import React from 'react'
import { AiOutlineRight } from "react-icons/ai";
import img13 from '../assets/images/13.jpg'

function About() {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 p-0'>
            <div className='section-title'>
             <h5>About</h5>
             <h6>Home <AiOutlineRight /> About</h6>
            </div>
        </div>
        <div className='col-12 organic'>
          <div className='d-flex mt-3 mb-3 justify-content-center'>
          <div className='organic-step'>
            <img src={img13} className='img-fluid' />
            <p className='step'>STEP - 01</p>
            <h5>Work Planning</h5>
            <p>Begin by conducting thorough soil tests to understand its composition, pH levels, and nutrient.</p>
          </div>  
          <div className='organic-step pt-5'>
            <img src={img13} className='img-fluid' />
            <p className='step'>STEP - 02</p>
            <h5>Work Planning</h5>
            <p>Begin by conducting thorough soil tests to understand its composition, pH levels, and nutrient.</p>
          </div>  
          <div className='organic-step'>
            <img src={img13} className='img-fluid' />
            <p className='step'>STEP - 03</p>
            <h5>Work Planning</h5>
            <p>Begin by conducting thorough soil tests to understand its composition, pH levels, and nutrient.</p>
          </div>  
          <div className='organic-step pt-5'>
            <img src={img13} className='img-fluid' />
            <p className='step'>STEP - 04</p>
            <h5>Work Planning</h5>
            <p>Begin by conducting thorough soil tests to understand its composition, pH levels, and nutrient.</p>
          </div> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default About