import React from 'react'
import Logo from '../assets/images/logo_light.png'

import { RiFacebookLine } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FiYoutube } from "react-icons/fi";
import { NavLink } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Footer() {
  return (
    <footer className='container-fluid'>
      <div className='row main-logo'>
         <div className='col-xxl-4 col-xl-4 mt-2 mb-2' data-aos="zoom-out-right">
             <div>
                <img src={Logo} className='img-fluid' />
             </div>
         </div>
         <div className='col-xxl-8 col-xl-8 d-flex gap-5 flex-wrap mt-2 mb-2' data-aos="zoom-out-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className='menu-link'>
              <h6 data-aos="zoom-out-right">Quick Links</h6>
              <ul>
                <li data-aos="zoom-out-right"><a href="#">Home</a></li>
                <li data-aos="zoom-out-right"><NavLink to="/about">About Us</NavLink></li>
                <li data-aos="zoom-out-right"><a href="#">Gallery</a></li>
                <li data-aos="zoom-out-right"><a href="#">Contact Us</a></li>
              </ul>
            </div>
            <div className='menu-link'>
              <h6>Address</h6>
              <ul>
                <li><a href="#"> Office No. 102, Empire state Building near
                 Udhana Darwaja, Surat, Gujarat, 395002</a></li>  
                <li><a href="#"> +123 456 7890</a></li>
                <li><a href="#"> info@phonedekho.co.in</a></li>
              </ul>
            </div>
            <div className='menu-link'>
              <h6>Follow Us</h6>
              <ul className='d-flex gap-3 align-items-center mt-3'>
                <li><a href="#" className='follow-icon'><RiFacebookLine  className='icon' /></a></li>
                <li><a href="#" className='follow-icon'><FaInstagram  className='icon' /></a></li>
                <li><a href="#" className='follow-icon'><FaLinkedinIn  className='icon' /></a></li>
                <li><a href="#" className='follow-icon'><FiYoutube  className='icon' /></a></li>
              </ul>
            </div>
         </div>
      </div>
    </footer>
  )
}

export default Footer
