import './App.css';
import './assets/scss/style.css';
import { createBrowserRouter , RouterProvider } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from "./components/Home";
import About from "./components/About";


function App() {

  const router = createBrowserRouter([
    {
      path: "/" ,
      element: <><Navbar/><Home /><Footer/></>
    },
    {
      path: "/about" ,
      element: <><Navbar/><About /><Footer/></>
    },
  ])
  return (
    <div className="App home-container">
        <RouterProvider router={router} /> 
    </div>
  );
}

export default App;
