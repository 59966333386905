import React from 'react'
import Logo from '../assets/images/logo.png'
import { IoSearchOutline } from "react-icons/io5";

function Navbar() {
  return (
    <nav className="main-nav">
        <img src={Logo} alt="" className='logo' />
        {/* <div className='main-search'>
          <div className='search-box'>
           <IoSearchOutline  className='search-icon'/>
           <input type='text' className='input-search' placeholder='Search' />
          </div>  
        </div> */}
    </nav>
  )
}

export default Navbar
