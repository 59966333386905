import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react'
import image1 from '../assets/images/01.png'
import image2 from '../assets/images/02.png'
import image3 from '../assets/images/03.png'
import image4 from '../assets/images/04.png'
import image5 from '../assets/images/05.png'
import image6 from '../assets/images/06.png'
import image7 from '../assets/images/07.png'
import image9 from '../assets/images/09.png'
import image10 from '../assets/images/10.png'
import Slider from "react-slick";
import { TiArrowLeft } from "react-icons/ti";
import { TiArrowRight } from "react-icons/ti";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function Home() {

  useEffect(() => {
    AOS.init({}); 
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <TiArrowRight />,
    prevArrow: <TiArrowLeft />,
    responsive: [
      {
        breakpoint: 1415,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 1139,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 656,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  };

  return (
    <main className='container-fluid'>
      <div className='row'>
        <div className='col-12 p-0 mt-3' data-aos="fade-up">
          <img src={image1} className='img-fluid' /> 
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <h6 className='title text-center'  data-aos="fade-right" data-aos-easing="ease-in-sine">Our Product</h6>
        </div>
        <div className='col-12'>
          <div className='row our-product' >
          <Slider {...settings} className='arrow-icons'>
                 <div>
                   <div data-aos="fade-right"  className='p-3 mt-2 mb-2 main-box'>
                   <div className='product-box'>
                       <img src={image2} className='img-fluid' />
                       <h6>Alovera plush longwear concealer</h6>
                       <a href='#' className='btn btn-primary btn-buy'>Buy Now</a>
                   </div>
                   </div>
                 </div>
                 <div>
                   <div data-aos="fade-right"  className='p-3 mt-2 mb-2 main-box'>
                   <div className='product-box'>
                   <img src={image3} className='img-fluid' />
                   <h6>Alovera plush longwear concealer</h6>
                   <a href='#' className='btn btn-primary btn-buy'>Buy Now</a>
                   </div>
                   </div>
                   </div>
                   <div >
                   <div data-aos="fade-right"  className='p-3 mt-2 mb-2 main-box'>
                   <div className='product-box'>
                    <img src={image4} className='img-fluid' />
                    <h6>Alovera plush longwear concealer</h6>
                    <a href='#' className='btn btn-primary btn-buy'>Buy Now</a>
                   </div>
                   </div>
                   </div>
                   <div >
                   <div data-aos="fade-right"  className='p-3 mt-2 mb-2 main-box'>
                    <div className='product-box'>
                       <img src={image5} className='img-fluid' />
                       <h6>Alovera plush longwear concealer</h6>
                       <a href='#' className='btn btn-primary btn-buy'>Buy Now</a>
                    </div>
                   </div>
                   </div>
                   <div >
                   <div data-aos="fade-right"  className='p-3 mt-2 mb-2 main-box'>
               <div className='product-box'>
                   <img src={image2} className='img-fluid' />
                   <h6>Alovera plush longwear concealer</h6>
                   <a href='#' className='btn btn-primary btn-buy'>Buy Now</a>
               </div>
             </div>
                   </div>
                   <div >
                   <div data-aos="fade-right"  className='p-3 mt-2 mb-2 main-box'>
               <div className='product-box'>
                   <img src={image3} className='img-fluid' />
                   <h6>Alovera plush longwear concealer</h6>
                   <a href='#' className='btn btn-primary btn-buy'>Buy Now</a>
               </div>
             </div>
                   </div>
                   <div >
                   <div data-aos="fade-right"  className='p-3 mt-2 mb-2 main-box'>
               <div className='product-box'>
                   <img src={image4} className='img-fluid' />
                   <h6>Alovera plush longwear concealer</h6>
                   <a href='#' className='btn btn-primary btn-buy'>Buy Now</a>
               </div>
             </div>
                   </div>
                   <div >
                   <div data-aos="fade-right"  className='p-3 mt-2 mb-2 main-box'>
               <div className='product-box'>
                   <img src={image5} className='img-fluid' />
                   <h6>Alovera plush longwear concealer</h6>
                   <a href='#' className='btn btn-primary btn-buy'>Buy Now</a>
               </div>
             </div>
              </div>
             </Slider>
          </div>
        </div>
      </div>  
      <div className='row'> 
         <div className='col-12 mt-5 p-0' data-aos="zoom-out-left">
           <img src={image6} className='img-fluid' />
         </div>
      </div>
      <div className='row elevate-your'>
        <div className='col-6 elevate-text' data-aos-offset="300" data-aos="zoom-out-up"
     data-aos-easing="ease-in-sine">
          <h5>Elevate Your Beauty</h5>
          <h6>Routine With <br/>
              LuxeLiner - Where Elegannce Meets <br/>
              Artistry</h6>
          </div>
          <div className='col-6 d-flex justify-content-end' data-aos="zoom-out-down" data-aos-offset="300"
     data-aos-easing="ease-in-sine">
            <img src={image7} className='img-fluid elevate-img' />
          </div>
      </div>
      <div className='row'>
        <div className='col-12 our-story' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <h6 data-aos="fade-down-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Our Story</h6>  
          <img src={image9} className='img-fluid' data-aos="fade-down-right" data-aos-offset="300" data-aos-easing="ease-in-sine"/>
          <div className='d-flex justify-content-center' data-aos="fade-down-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <p>It is a long established fact that a reader will be distracted by the readable
              content of a page when looking at its layout. The point of using Lorem Ipsum is that
              it has a more-or-less normal distribution of letters, as opposed 
             to using 'Content here It is a long established fact that a reader
              will be distracted by the readable</p>
          </div>
        </div>
      </div>
      <div className='row face-moisturizer'>
        <div className='col-6 user-img' data-aos="flip-left" data-aos-offset="300"
     data-aos-easing="ease-in-sine">
          <img src={image10} className='img-fluid' />
        </div>
        <div className='col-6' data-aos="flip-right" data-aos-offset="300"
     data-aos-easing="ease-in-sine">
           <div className='love-it'>
           <h5 data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">why we love it</h5>
           <div data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <h6>Face moisturizer</h6>
            <p>hydrates, nourishes, and softens the skin for <br/>
              a non-oily, smooth, silky finish.</p>
           </div>
           <div data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <h6>Eye Cream</h6>
            <p>helps brighten, hydrate, and reduce the look of puffiness <br/>
              and dark circles.</p>
           </div>
           <div data-aos="zoom-in" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <h6>clarifying facial oil</h6>
            <p>helps balance, clarify, and improve skin’s texture leaving <br/>
              it with a healthy-looking glow.</p>
           </div>
           </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 our-story' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <h6 data-aos="fade-down-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Womens Empowerment</h6>
          <img src={image9} className='img-fluid' data-aos="fade-down-right" data-aos-offset="300" data-aos-easing="ease-in-sine"  />
          <div className='d-flex justify-content-center' data-aos="fade-down-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <p>It is a long established fact that a reader will be distracted by the readable
              content of a page when looking at its layout. The point of using Lorem Ipsum is that
              it has a more-or-less normal distribution of letters, as opposed 
             to using 'Content here It is a long established fact that a reader
              will be distracted by the readable</p>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 latest-update'>
          <h5 data-aos="zoom-in-right" data-aos-offset="300" data-aos-easing="ease-in-sine">Stay connected with us</h5>
          <p data-aos="zoom-in-right" data-aos-offset="300" data-aos-easing="ease-in-sine">To get latest update and news</p>
          <div className='email-subscribe' data-aos="zoom-in-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <input type='text' placeholder='Type Your Email Address' />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
    </main>
  )
}
